<template>
  <main>
    <CCard>
      <CCardBody class="d-flex justify-content-between">
        <div>
          <h5 class="text-info">
            Google 登入
          </h5>
          <div>
            啟用後可於網站或應用前台使用 Google 登入。
          </div>
          <div v-if="ShopSetting.EnableGoogleLogin" class="mt-4">
            <CInput label="用戶端編號" type="string" v-model="MerchantData['google-oauth'].ClientID" placeholder="" description="選填欄位，系統已內建 Google 登入，若需要使用自定義的Google登入方式，請於「Google Cloud > API和服務 > 憑證」取得「用戶端編號」" />
          </div>
          <CButton color="success" class="px-4 mt-3" @click="Save('google-oauth')" size="sm">
            {{ $t('Global.Update') }}
          </CButton>
        </div>
        <CSwitch color="success" :checked.sync="ShopSetting.EnableGoogleLogin" />
      </CCardBody>
      <CElementCover
        v-if="(Submit === true)"
        :boundaries="[{ sides: ['center', 'center'], query: '#DeleteSubmit' }]"
        :opacity="0.8"
      >
        <CSpinner size="sm" color="primary"/>
      </CElementCover>
    </CCard>
    <CCard>
      <CCardBody class="d-flex justify-content-between">
        <div>
          <h5 class="text-info">
            LINE 登入
          </h5>
          <div>
            啟用後可於網站或應用前台使用 LINE 登入，請前往「 <a href="https://developers.line.biz/console" style="color: #00b900">https://developers.line.biz/console</a> 」取得串接資訊並進行相關設定。
          </div>
          <div v-if="ShopSetting.EnableLINELogin" class="mt-4">
            <CInput :value="`https://${$store.state.project.Domain}/api/member/login/oauth/callback/line`" label="Callback URL" disabled>
              <template #append>
                <CButton type="button" color="info" @click="CopyToClipboard(`https://${$store.state.project.Domain}/api/member/login/oauth/callback/line`)">
                  <CIcon name="cil-copy" class="c-icon-custom-size mr-2 my-0" />{{ $t('Global.Copy') }}
                </CButton>
              </template>
            </CInput>
            <CInput label="Channel ID" type="string" v-model="MerchantData['line-oauth'].ChannelID" placeholder="" description="" />
            <CInput label="Channel secret" type="string" v-model="MerchantData['line-oauth'].SecretID" placeholder="" description="" />
          </div>
          <CButton color="success" class="px-4 mt-3" @click="Save('line-oauth')" size="sm">
            {{ $t('Global.Update') }}
          </CButton>
        </div>
        <CSwitch color="success" :checked.sync="ShopSetting.EnableLINELogin" />
      </CCardBody>
      <CElementCover
        v-if="(Submit === true)"
        :boundaries="[{ sides: ['center', 'center'], query: '#DeleteSubmit' }]"
        :opacity="0.8"
      >
        <CSpinner size="sm" color="primary"/>
      </CElementCover>
    </CCard>
  </main>
</template>

<route>
{
"meta": {
"label": "登入方式"
}
}
</route>

<script>
export default {
  name: 'MemberLoginProviders',
  layout: 'manage',
  components: {
  },
  data() {
    return {
      ShopSetting: {},
      MerchantData: {
        'line-oauth': {
          Taxonomy: 'OAuth'
        },
        'google-oauth': {
          Taxonomy: 'OAuth'
        }
      },
      Submit: false
    }
  },
  computed: {
  },
  mounted() {
    this.$Progress.start()
    this.Init().then(() => {
      this.$Progress.finish()
    }).catch((err) => {
      this.$Progress.fail()
      this.$notify({
        group: 'notify',
        title: this.$t('Message.Error'),
        text: this.$t('Message.Global/ErrorMessage') + err.msg,
        type: (err.type ? err.type : 'error')
      })
    })
  },
  methods: {
    Init() {
      return Promise.all([
        this.GetShopSetting()
      ])
    },
    GetShopSetting () {
      return Promise.all([
        this.$store.dispatch('InnerRequest', {
          url: '/system/get/MerchantData',
          method: 'post'
        }).then(({ data }) => {
          this.MerchantData = {
            ...this.MerchantData,
            ...data
          }
        }),
        this.$store.dispatch('InnerRequest', {
          url: '/system/get/ShopSetting',
          method: 'post'
        }).then(({data}) => {
          this.ShopSetting = data
        })
      ])
    },
    Save (system) {
      this.Submit = true
      this.$store.dispatch('InnerRequest', {
        url: '/system/merchant/set',
        method: 'post',
        data: {
          ...this.MerchantData[system],
          System: system
        }
      })
      return this.$store.dispatch('InnerRequest', {
        url: '/system/set/ShopSetting',
        method: 'post',
        data: {
          ShopSetting: this.ShopSetting
        }
      }).then(() => {
        this.Submit = false
        this.$notify({
          group: 'notify',
          title: this.$t('Message.Success'),
          text: this.$t('Message.System/ModifySuccess'),
          type: 'success'
        })
      }).catch((err) => {
        this.Submit = false
        this.$notify({
          group: 'notify',
          title: this.$t('Message.Error'),
          text: this.$t('Message.System/ModifyFail') + err.msg,
          type: (err.type ? err.type : 'error')
        })
      })
    },
    CopyToClipboard (string) {
      if (navigator && navigator.clipboard && navigator.clipboard.writeText) {
        this.$notify({
          group: 'notify',
          title: this.$t('Message.Success'),
          text: this.$t('Message.Global/CopySuccess'),
          type: 'success'
        })
        return navigator.clipboard.writeText(string)
      }
      return Promise.reject('The Clipboard API is not available.');
    }
  }
}
</script>

